:root {
  --lightgray: #5A72A0;
  --gray: #5A72A0;
  --orange: #9AC8CD;
  --darkGrey: #9AC8CD;
  --caloryCard: #9AC8CD;
  --planCard: linear-gradient(210.41deg, #9AC8CD 1.14%, #9AC8CD 100.75%);
  --appColor: #070F2B;
}

.App {
  background-image: url('assets/background.png'); /* Reference your background.png */
  background-size: cover; /* Ensure it covers the full container */
  background-position: center; /* Center the image */
  background-attachment: fixed; /* Make it fixed for a parallax effect */
  mix-blend-mode: overlay;
  background-color: var(--appColor);
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}

::-webkit-scrollbar {
  display: none;
}

.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.btn {
  background-color: black;
  color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.btn:hover {
  cursor: pointer;
}

.blur {
  background: #9c9c9c;
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -5;
}
