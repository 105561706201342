/* General Styles */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  min-height: 100vh; /* Full screen height */
  position: relative;
  overflow: hidden;
}

.left-h {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
}

.right-h {
  flex: 1;
  position: relative;

}

/* Progress Bar Container */
.progress-container {
  margin-top: 2rem;
  text-align: right;
  width: 100%; /* Full width of the container */
}

.progress-bar {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  width: 100%;
  height: 30px;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
}

.progress-fill {
  background-color: #9AC8CD;
  height: 100%;
  border-radius: 10px;
  transition: width 1.5s ease-in-out, background-color 0.5s ease;
}

.progress-fill:hover {
  background-color: #7ab3b7;
}


.progress-container h3, .progress-container p {
  color: white;
  margin: 0;
  font-size: 1rem;
}

/* Hero Image */

.hero-img {
  width: 80%;
  max-width: 500px;
  height: auto;
  object-fit: cover;
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease;
}

.hero-img:hover {
  transform: scale(1.08);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
}

.hero-blur {
  width: 32rem;
  height: 30rem;
  position: absolute;
  left: 0;
  z-index: -1;
  filter: blur(100px);
}

/* The Best */
.the-best {
  margin-top: 4rem;
  background-color: rgba(57, 61, 66, 0.9);
  border-radius: 50px;
  padding: 1rem 2rem;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  align-self: flex-start;
}

/* Hero Text */
.hero-text {
  display: center;
  flex-direction: column;
  gap: 1rem;
  text-transform: uppercase;
  font-size: 4vw;
  font-weight: bold;
  color: white;
}

.hero-text > div:nth-of-type(3) {
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}

.future-image-placeholder {
  width: 100%; /* Full width for responsiveness */
  height: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure it doesn't exceed the container width */
  border-radius: 10px; /* Rounded corners */
  object-fit: cover; /* Make sure the aspect ratio is maintained */
  display: block;
}

/* Desktop View: Increase the size significantly */
@media screen and (min-width: 1200px) {
  .future-image-placeholder {
    max-width: 4000px; /* Set max width to the image's native resolution */
    width: 100%; /* Ensure full width of the parent container */
    height: auto; /* Maintain the aspect ratio */
  }
}


/* Tablet View */
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .future-image-placeholder {
    max-width: 1000px; /* Adjust to a smaller size for tablet view */
  }
}

/* Mobile View */
@media screen and (max-width: 768px) {
  .future-image-placeholder {
    max-width: 100%; /* Full width on smaller screens */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px; /* Slightly smaller border-radius for mobile */
  }
}



/* Hero Buttons */
.hero-btns {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.hero-btns > .btn {
  color: white;
  background-color: var(--orange);
  padding: 0.8rem 2rem;
  border-radius: 25px;
  font-size: 1.1rem;
  transition: background-color 0.3s ease-in-out;
  border: none;
  cursor: pointer;
}

.hero-btns > .btn:nth-child(2) {
  background-color: transparent;
  border: 2px solid var(--orange);
}

.hero-btns > .btn:hover {
  background-color: white;
  color: var(--orange);
}

/* Tablet View */
@media screen and (max-width: 1024px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 2rem 1rem;
  }

  .left-h {
    align-items: center;
  }

  .hero-text {
    font-size: 3rem;
  }

  .hero-text > div:nth-of-type(3) {
    font-size: 1rem;
    width: 90%;
    margin: auto;
  }

  .right-h {
    margin-top: 2rem;
    max-width: 80%;
  }

  .hero-img {
    width: 100%;
    max-width: 600px;
  }

  .hero-btns {
    justify-content: center;
  }

  .the-best {
    margin-top: 2rem;
    padding: 1rem 2rem;
  }

  .progress-container {
    margin-top: 1rem;
  }
}

/* Mobile View */
@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 1rem;
  }

  .left-h {
    align-items: center;
    padding: 0;
  }

  .hero-text {
    font-size: 2.5rem;
  }

  .hero-text > div:nth-of-type(3) {
    font-size: 1rem;
    width: 100%;
    text-align: center;
  }

  .hero-btns {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .hero-btns > .btn {
    width: 90%;
    max-width: 300px;
    margin: auto;
  }

  .right-h {
    position: center;
    margin-top: 2rem;
    max-width: 90%;
  }

  .hero-img {
    width: 100%;
    max-width: 100%;
    margin: auto;
  }

  .hero-blur {
    width: 14rem;
    height: 14rem;
  }

  .the-best {
    align-items: center;
    margin-top: 1rem;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }

  .progress-container {
    width: 100%;
    margin-top: 1rem;
  }
}