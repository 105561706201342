/* Container for the entire section */
.gallery-section {
    padding: 50px 20px;
    
    text-align: center;
    color: #ffffff;
  }
  
  /* Heading styling */
  .gallery-heading {
    margin-bottom: 20px;
  }
  
  .progress-text2 {
    text-transform: uppercase;
    font-size: 2.5vw; /* Scales with viewport width */
    font-weight: bold;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    display: block;
    width: 100%;
  }
  
  .stroke-text2 {
    color: transparent;
    -webkit-text-stroke-width: 1.5px; /* Stroke for outline effect */
    -webkit-text-stroke-color: white;
    font-size: 5vw;
  }
  
  /* Gallery container styling */
  .gallery {
    width: 100%;
    max-width: 500px; /* Limit width for desktop views */
    margin: 0 auto; /* Center the gallery container */
    position: relative;
    overflow: hidden;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  }
  
  /* Image styling */
  .gallery-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover; /* Ensures images cover the container */
    transition: opacity 1s ease-in-out;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .gallery-section {
      padding: 30px 15px;
    }
    .progress-text2 {
      font-size: 5vw; /* Larger text on smaller screens */
    }
    .gallery {
      border-radius: 8px;
    }
  }
  
  @media (max-width: 480px) {
    .progress-text2 {
      font-size: 5vw;
    }
    .stroke-text2 {
        font-size: 10vw;
      -webkit-text-stroke-width: 1.2px; /* Slightly smaller stroke on mobile */
    }
  }
  