/* Progress.css */
.progress-container {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  
  .progress-text {
    text-transform: uppercase;
    font-size: 5vw; /* Scales with viewport width, bigger on mobile */
    font-weight: bold;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    display: block;
    width: 100%;
  }
  .progress-text2 {
    text-transform: uppercase;
    font-size: 3vw; /* Scales with viewport width, bigger on mobile */
    font-weight: bold;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    display: block;
    width: 100%;
  }
  .stroke-text {
    color: transparent;
    -webkit-text-stroke-width: 1.5px; /* Increase stroke width for visibility */
    -webkit-text-stroke-color: white;
    font-size: inherit;
  }
  
  .progress-bar {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    width: 90%; /* Adjusted to make it wider on all devices */
    max-width: 500px;
    height: 35px;
    margin: 1rem auto;
    overflow: hidden;
  }
  
  .progress-fill {
    background-color: #9AC8CD;
    height: 100%;
    border-radius: 10px;
    transition: width 1.5s ease-in-out, background-color 0.5s ease;
    position: relative; /* Add this to enable absolute positioning of the text */
  }
  
  .progress-text3 {
    position: absolute;
    left: 80%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none; /* Prevent the text from interfering with hover effects */
    font-size: 1rem; /* Adjust font size if needed */
  }
  
  
  .progress-fill:hover {
    background-color: #7ab3b7;
  }
  
  .progress-subtext {
    font-size: 1.7rem; /* Increased base font size */
    font-weight: 300;
    color: white;
    letter-spacing: 1px;
    text-align: center;
    text-transform: none;
    margin-top: 0.5rem; /* Space it nicely below the progress bar */
  }
  
  /* Media Queries for responsiveness */
  @media screen and (max-width: 1024px) {
    .progress-text {
      font-size: 6vw; /* Slightly larger for tablets */
    }
  
    .progress-subtext {
      font-size: 1.5rem; /* Adjust subtext size */
    }
  
    .progress-bar {
      width: 95%;
      height: 30px; /* Adjust height */
    }
  }
  
  @media screen and (max-width: 768px) {
    .progress-text {
      font-size: 8vw; /* Make it larger for small tablets */
    }
     
    .progress-text2 {
      font-size: 5.5vw; /* Make it larger for small tablets */
    }
    .progress-subtext {
      font-size: 1.4rem;
    }
  
    .progress-bar {
      width: 100%;
      max-width: 100%; /* Full width for smaller screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .progress-text {
      font-size: 10vw; /* Bigger text on small devices */
    }
  
    .stroke-text {
      -webkit-text-stroke-width: 2px; /* Increase stroke width on small devices for better visibility */
    }
  
    .progress-subtext {
      font-size: 1.3rem;
    }
  
    .progress-bar {
      width: 100%;
      height: 25px;
    }
  }
  